<template>
    <div id="footer">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="footer-baslik">Kurumsal</div>
                        <div class="footer-altbaslik"><span>Hakkimizda</span></div>
                        <div class="footer-altbaslik"><span>Vizyon</span></div>
                        <div class="footer-altbaslik"><span>Misyon</span></div>
                        <div class="footer-altbaslik"><span>Iletisim</span></div>
                    </div>
                    <div class="col-lg-4">
                        <div class="footer-baslik">Yardım</div>
                        <div class="footer-altbaslik">
                            <span><a href=aydinlatma.pdf>Müşteri Aydınlatma Metni</a></span>
                        </div>
                         <div class="footer-altbaslik">
                            <span><a href=kvkk.pdf>KVKK Politikası</a></span>
                        </div>
                        <div class="footer-altbaslik">
                            <router-link to="/kaydol"><span>Yeni Üyelik</span></router-link>
                        </div>
                        <div class="footer-altbaslik">
                            <router-link to="/profil/geri-bildirim"><span>Geri Bildirim</span></router-link>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="footer-baslik">İletişim</div>
                        <div class="footer-altbaslik">
                            <span><i class="fas fa-phone-alt"></i></span>
                            <span><a href="tel:0 (543) 211 03 09">0 (543) 211 03 09</a></span>
                        </div>
                        <div class="footer-altbaslik">
                            <span><i class="fas fa-envelope"></i></span>
                            <span><a href="mailto:adadakiler@alkid.com.tr?subject=Bize Mail Bırakın">adadakiler@alkid.com.tr</a></span>
                        </div>
                        <div class="footer-ios-play">
                            <span><a href="https://apps.apple.com/us/app/adadakiler-sanal-market/id6448932349?l=tr" target="_blank"><img src="@/assets/svg/icon-ios.svg" alt="ios"></a></span>
                            <span><a href="https://play.google.com/store/apps/details?id=com.zdc.android.adadakiler&hl=TR"><img src="@/assets/svg/icon-play.svg" alt="play"></a></span>
                        </div>
                        <div class="footer-social">
                            <SocialMediaIcons />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="footer-bottom">
                            <router-link to="/">
                                <img src="@/assets/img/logo-footer.png" alt="logo">
                            </router-link>
                            <span>2023 ® Adadakiler Sanal Market |</span> Tüm hakları saklıdır. Kredi kartı bilgileriniz 256bit SSL sertifikası ile korunmaktadır.
                            <div><img src="@/assets/img/visa-master.png" alt="logo"></div>

                     
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="footer-son">
                            <span>Powered By <a href="https://market.zdc.com.tr/" target="_blank">ZDC Bilişim</a></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
</template>

<script>
import SocialMediaIcons from "@/components/SocialMediaIcons"

export default {
    components : {
        SocialMediaIcons
    }
}
</script>

<style scoped>
    @media (max-width : 990px){
        #footer{
            display: none !important;
        }
    }
    #footer{
        border-top: 1px solid #d8d8d8;
        padding: 40px 40px 0 40px;
        font-family: 'Trebuchet MS';
    }
    .footer-baslik{
        font-size: 16px;
        color: #ff5e00;
        font-weight: 800;
        padding-bottom: 25px;
    }
    .footer-baslik:after {
        content: ""; /* This is necessary for the pseudo element to work. */ 
        display: block; /* This will put the pseudo element on its own line. */
        width: 7%;
        padding-top: 2px;
        border-bottom: 2px solid #E53F1A;
    }
    .footer-altbaslik{
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 10px;
        color: black;
        transition: .5s;
    }
    .footer-altbaslik span{
        transition: .3s;
    }
    .footer-altbaslik a{
        color: black;
        cursor: pointer;
    }
    .footer-altbaslik span:hover{
        color: #E53F1A;
        cursor: pointer;
    }
    .footer-altbaslik span i{
        border: 2px solid #eeeeee;
        border-radius: 20px;
        padding: 10px;
        transition: .3s;
    }
    .footer-altbaslik span i:hover{
        border-color: #d6d6d6;
    }
    .footer-altbaslik span:nth-child(2){
        font-weight: 400;
        padding-left: 10px;
    }
    i{
        color: #4d4d4d;
    }
    .footer-social{
        margin-top: 10px;
    }
    .footer-social i{
        border: 2px solid #eeeeee;
        border-radius: 30px;
        padding: 15px;
        transition: .3s;
        margin-right: 10px;
        font-size: 18px;
    }
    .fa-facebook-f{
        min-width: 50px;
        padding-left: 17px !important;
    }
    .footer-social span i:hover{
        border: 2px solid #E53F1A;
        background: #E53F1A;
        color: white;
        cursor: pointer;
        box-shadow: 0 4px 4px 0 #6b6b6b;
    }
    .footer-ios-play{
        margin-top: 20px;
    }
    .footer-ios-play span{
        margin-right: 20px;
    }
    .footer-ios-play img{
        transition: .3s;
    }
    .footer-ios-play span:hover img{
        transform: translateY(-5px);
        box-shadow: 0 5px 5px 0 rgb(151, 151, 151);
    }
    .footer-bottom::before{
        content: "";
        display: block;
        margin: 30px 0 30px 0;
        width: 100%;
        padding-top: 2px;
        border-bottom: 1px solid #d8d8d8;
    }
    .footer-bottom img{
        width: 200px;
        object-fit: contain;
    }
    .footer-bottom{
        font-size: 14px;
        color: #5e5e5e;
    }
    .footer-bottom span{
        font-weight: 800;
        padding-left: 20px;
        color: #424242;
    }
    .footer-bottom div{
        margin-top: 10px;
    }
    .footer-son{
        margin: 20px 0 5px 0;
        display: flex;
        justify-content: center;
        font-size: 13px;
    }
    .footer-etbis{
        position: absolute;
        top: 80px;
        right: 20px;
    }
</style>