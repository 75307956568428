import { render, staticRenderFns } from "./BottomNavMenu.vue?vue&type=template&id=38f74e30&scoped=true&"
import script from "./BottomNavMenu.vue?vue&type=script&lang=js&"
export * from "./BottomNavMenu.vue?vue&type=script&lang=js&"
import style0 from "./BottomNavMenu.vue?vue&type=style&index=0&id=38f74e30&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38f74e30",
  null
  
)

export default component.exports